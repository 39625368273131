import React from "react";

import StaticLayout from "../components/StaticLayout";
import imgSrc1 from "../images/opening.jpg";
import imgSrc2 from "../images/openingsdansen.jpg";
import imgSrc3 from "../images/Tango-1.jpg";
import imgSrc4 from "../images/Spork.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-25"
        className="post-25 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Dansaanbod</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <h2>
            <strong>Groepslessen</strong>
          </h2>
          <p>&nbsp;</p>
          <ul>
            <li>
              <h4>Stijldansen</h4>
              <p>
                Heb je zin om in groep te proeven van verschillende soorten
                dans?&nbsp;Dan is onze cursus ‘Start To Dance’ helemaal iets
                voor jou!
              </p>
              <p>
                De cursus is op maat van beginnende dansers of mensen met weinig
                ervaring met stijldansen. We beginnen met een aantal lessen
                mambo, chacha, quickstep en Engelse wals, en daarna komen ook de
                jive, rumba en tango aan bod. Kortom, voor elk wat wils!
              </p>
              <p>
                Iedereen die zin heeft om een pasje te wagen in de danswereld is
                welkom. Samen met je (dans)partner leer je de basisbeginselen.
                Misschien is dat wel het begin van een mooie danscarrière.
              </p>
              <p>
                <img
                  className="alignnone size-medium wp-image-170"
                  src={imgSrc1}
                  alt=""
                  width={300}
                  height={200}
                />
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              <h4>Disco-Boogie</h4>
              <p>
                Heeft de seventies muziek het swingend vuur in jou aangewakkerd?
                Kom dan zeker eens kennis maken met Disco-Boogie! De
                Disco-Boogie wordt ook wel eens de discoswing genoemd. Deze dans
                is ideaal voor paren die graag uit de bol gaan op pop- of
                discomuziek (denk maar aan&nbsp;“the first, the last, my
                everything” van Barry White, maar ook aan “on the floor” van
                Jennifer Lopez).
              </p>
              <p>
                Het is een mix van elementen uit verschillende dansen. Zo kan je
                de passen en de danshouding van de foxtrot terugvinden, maar
                evengoed technieken uit de Latindansen en de choreografie van de
                rock-‘n-roll. De disco-boogie is door het eenvoudige dansritme
                heel geschikt voor beginnende dansers. Ook heel belangrijk: deze
                dans is gemakkelijk te dansen op feestjes.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              <h4>Solo Dance</h4>
              <p>
                Geen danspartner of kan je gewoon niet stilzitten bij muziek?
                Doe dan mee met onze leuke Solo Dance lessen. Je leert leuke
                choreo’s op een Samba, Chacha, Salsa, Bachata, Mambo en nog veel
                meer.
              </p>
              <p>
                Goed voor de lijn en goed voor het geheugen. Calorieën
                verbranden tijdens het dansen op leuke hedendaagse muziek. Wat
                wil je nog meer?
              </p>
              <p>
                Geschikt voor beginners en gevorderden, maar zeker niet alleen
                voor dames.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              <h4>Salsa &amp; Bachata</h4>
              <p>
                Maak kennis met twee zuiderse dansstijlen! In deze cursus leer
                je de basispassen van de Salsa en de Bachata.
              </p>
              <p>
                Salsa is een romantische, maar toch energieke Latin partnerdans.
                Het is een spannende dans op muziek met een snel tempo, leuk om
                te dansen en om naar te kijken! De term ‘salsa’ komt uit New
                York, maar de dans zelf heeft wortels in Latijns Amerika. Het is
                moeilijk om een definitie te geven van salsa, want in
                verschillende landen, en zelfs in verschillende steden wordt hij
                anders gedanst. Eén ding hebben alle salsadansers wél gemeen:
                hun heupen staan tijdens het dansen niet stil!
              </p>
              <p>
                Ook de Bachata zo’n intieme Latin partnerdans. Dansers bewegen
                met hun heupen, in perfecte harmonie. Bachata is afkomstig uit
                de Dominicaanse Republiek. Er zijn verschillende types Bachata,
                maar de kenmerkende snelle passen en sensualiteit komen altijd
                terug. Zin om te dansen in zuiderse sferen? Waag je dan aan onze
                snelcursus Salsa &amp; Bachata.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              <h4>Nightclub Two Step</h4>
              <p>
                Salsa Nightclub Two Step is een vlotte, sociale dans die gedanst
                wordt op leuke, langzame muziek (Vb. Chris de Burgh – The Lady
                in Red, Ed Sheeran – Thinking out loud, …).&nbsp;&nbsp;Het is
                bedacht in 1960 door Buddy Schwimmer&nbsp; en wordt gedanst op
                muziek met een 4/4 maat en een tempo van 16-22 maten per minuut.
                Het basis ritme is Quick Quick Slow.
              </p>
              <p>
                Door oa. het ritme lijkt&nbsp;het een beetje op rumba, maar toch
                is het een hele andere dans. Je danst hier 2 keer zo snel op
                dezelfde muziek. Vandaar dat langzame muziek erg geschikt is
                voor de Nightclub Two Step.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h2>
            <strong>Openingsdans</strong>
          </h2>
          <p>&nbsp;</p>
          <p>
            Sta jij op het punt te trouwen en heb je meer zenuwen voor de
            openingsdans dan om het jawoord geven? Wij helpen graag jouw zenuwen
            onder controle te krijgen! Via enkele privé- en/of groepslessen
            stomen wij jou klaar voor een fantastische dans en ons assortiment
            is ruim: van een traditionele slow tot breakdancende bruidengommen
            en booty shakende bruiden.
          </p>
          <p>
            Daarnaast helpen we je niet alleen met de dans maar verlenen we ook
            advies omtrent de juiste muziekkeuze. Dankzij onze deskundige
            begeleiding kan jij op jouw grote dag met een gerust hart op de
            dansvloer staan.
          </p>
          <p>
            Heb je daarnaast ook familieleden wiens dansmoves niet echt
            partyproof zijn en willen zij ook graag enkele lessen volgen? Ook
            zij kunnen bij ons terecht.
          </p>
          <p>Heb je nog vragen? Neem dan gerust contact met ons op!</p>
          <p>&nbsp;</p>
          <p>
            <img
              className="alignnone size-medium wp-image-175"
              src={imgSrc2}
              alt=""
              width={244}
              height={300}
            />
          </p>
          <h2>&nbsp;</h2>
          <h2>
            <strong>Privélessen</strong>
          </h2>
          <p>&nbsp;</p>
          <p>
            In onze dansstudio kan iedereen terecht voor privélessen, of je nu
            een recreatieve danser bent of een wedstrijddanser. Heb je geen tijd
            om elke week deel te nemen aan groepslessen, of wil je graag een
            intensievere begeleiding, eventueel bovenop de groepslessen? Dan
            zijn privélessen de oplossing voor jou. Je leert wat je wilt,
            wanneer je wilt! Privélessen nemen betekent niet dat je alleen moet
            dansen, er is ook de mogelijkheid om met een aantal vrienden
            privélessen te nemen. Je kan zelfs privédanslessen cadeau doen met
            onze cadeaukaart.
          </p>
          <p>
            <u>Recreatieve danser</u>
          </p>
          <p>
            In onze privélessen krijg je de onverdeelde aandacht van de
            dansleraar. Dat heeft natuurlijk als voordeel dat de danslessen
            volledig op jouw maat gemaakt kunnen worden, en dat we aandacht
            kunnen geven aan jouw werkpunten. Dat kunnen passen zijn die je nog
            niet onder de knie hebt, maar ook je houding, je techniek en
            choreografieën uit de cursussen. Later instromen in groepslessen is
            enkel mogelijk als je al enige danservaring hebt, dus ook daarvoor
            zijn onze privélessen het ideale opstapje!
          </p>
          <h2>&nbsp;</h2>
          <p>
            <img
              className="alignnone size-medium wp-image-179"
              src={imgSrc3}
              alt=""
              width={219}
              height={300}
            />
          </p>
          <p>
            <u>Wedstrijddanser</u>
          </p>
          <p>
            Dans jij met je partner vlot door regionale, nationale of
            internationale wedstrijden? Of heb je die ambitie? Wij bieden
            dansparen ondersteuning op gebied van choreografie, techniek en
            houding. Bij wedstrijddansen is het dansen natuurlijk van groot
            belang, maar ook over de dingen die er allemaal bij komen kijken,
            kan je bij ons terecht voor advies. Samen werken we een persoonlijk
            trainingsschema uit met bijzondere aandacht voor kleding en
            voedingsadvies.
          </p>
          <h2>&nbsp;</h2>
          <p>&nbsp;</p>
          <h2>
            <strong>
              Workshops: Vrijgezellen/Bedrijfsactiviteit/Evenementen
            </strong>
          </h2>
          <p>&nbsp;</p>
          <p>
            Organiseer jij een bachelor(ette)party? Een teambuilding? Of een
            ander event? Dan is een workshop op maat perfect voor jou.
          </p>
          <p>
            Wie zin heeft in een dagje gezellig dansen met collega’s of
            vrienden, kan bij ons een leuke workshop boeken. In die workshop
            maak je op korte tijd kennis met een dansstijl naar keuze. Mambo,
            Weense Wals, Salsa, Bachata, … het kan allemaal! Je leert de basis
            en je kan al snel de eerste passen op de dansvloer wagen. Plezier
            gegarandeerd! Onze workshops kunnen in onze dansstudio, maar ook op
            een locatie naar keuze plaatsvinden.
          </p>
          <p>
            Ben je eerder op zoek naar een show/demonstratie? Ook dan kan je
            zeker en vast bij ons terecht. Wij bespreken graag samen met jou
            naar wat je op zoek bent. Meer informatie hierover vind je onder de
            sectie “demonstraties”.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h2>
            <strong>Demonstraties</strong>
          </h2>
          <p>&nbsp;</p>
          <p>
            Onze dansdemonstraties geven dat extra beetje fun aan je feestje!
            Heb je binnenkort een bedrijfsfeest, een bruiloft of een ander feest
            dat wel wat danspassen kan gebruiken?
          </p>
          <p>
            De stijl en het thema van de dansen die worden getoond tijdens de
            demonstratie kunnen in samenspraak gebeuren. Het gamma is dan ook
            ruim: Samba, cha cha, rumba, jive etc. Keuze zat!
          </p>
          <p>
            Het is uiteraard ook mogelijk om een demonstratie te combineren met
            een workshop. Zo kunnen alle gasten op jouw bal, personeelsfeest of
            de opening van je bedrijf een pasje op de dansvloer wagen!
          </p>
          <p>
            Wil je verder meer weten over hoe zo’n demonstratie ineen steekt?
            Neem dan geheel vrijblijvend contact met ons op.
          </p>
          <p>
            <img
              className="alignnone size-medium wp-image-176"
              src={imgSrc4}
              alt=""
              width={200}
              height={300}
            />
          </p>
          <p>&nbsp;</p>
          <h2>&nbsp;</h2>
          <p />
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
